import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
import FileEmbed from '../../../components/FileEmbed';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I've been putting some decent effort into creating content for this blog lately, and I decided it would be nice to start tracking hits to the site. Most analytics platforms have terrible privacy practices or cost a bunch of money, so I decided to simply build my own.`}</p>
    <p>{`Since I've been learning Motoko programming for my Coding with Kyle series (now available on `}<a parentName="p" {...{
        "href": "https://www.youtube.com/playlist?list=PLuhDt1vhGcrfQGLWqhUo9-DFD5JaHqCh1"
      }}>{`YouTube`}</a>{`), I decided to go ahead and build this using the Internet Computer as a cheap and open backend.`}</p>
    <h2 {...{
      "id": "tracking-as-little-as-possible"
    }}><a parentName="h2" {...{
        "href": "#tracking-as-little-as-possible"
      }}>{`Tracking as little as possible`}</a></h2>
    <p>{`Really, all I care about is getting a sense of what people are looking at, and what kind of a device they're coming from. `}</p>
    <p>{`To that end, I came up with this simple structure for my data: `}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`stable var visitSummaries `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Trie`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Trie`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Route`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`VisitSummary`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Trie`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`empty`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

stable var logs `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`VisitRecord`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`Basically, what we have here is a `}<inlineCode parentName="p">{`Trie`}</inlineCode>{`, which I'm using like a key-value Map of routes, which are URL's, and a Summary of the visits of that route. Then, I'm also keeping a log of records, as a simple array. `}</p>
    <p>{`The types look like this: `}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Route`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`DeviceType`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` #`}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Mobile`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{` #`}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Desktop`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`VisitRecord`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    deviceType`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`DeviceType`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    time`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Time`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Time`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    route`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Route`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`VisitSummary`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    route`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Route`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    total`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat32`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    mobile`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat32`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    desktop`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat32`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`Pretty straightforward - the Route is a string, just the URL. DeviceType is a Motoko Variant, which will be one of the attributes, either `}<inlineCode parentName="p">{`#Mobile`}</inlineCode>{` or `}<inlineCode parentName="p">{`#Desktop`}</inlineCode>{`. Then, I have two different records that I update when someone visits a page - a simple log with the deviceType, a timestamp, and the route, as well as a summary of each route.`}</p>
    <p>{`This allows me to quickly pull up a summary of each route, with things like the total visits, and the mobile / desktop distribution. The logs will let me do more rigorous data exploration down the road if I so choose. `}</p>
    <p>{`Here's the rest of the code, and a link to `}<a parentName="p" {...{
        "href": "https://github.com/krpeacock/page-visits"
      }}>{`the repo`}</a>{`.`}</p>
    <FileEmbed language="mo" fileName="main.mo" url="https://raw.githubusercontent.com/krpeacock/page-visits/main/src/page_visits/main.mo" mdxType="FileEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      